<template>
    <div>
        <b-dropdown position="is-bottom-left" max-height="20em" scrollable aria-role="list">
            <template #trigger>
                <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                    <span class="icon is-small">
                        <font-awesome-icon :icon="['fa', 'angle-down']" />
                    </span>
                </button>
            </template>

            <b-dropdown-item custom aria-role="listitem">
                <b-input type="search" placeholder="Customer Line Name" ref="customerLineDropdownFilterRef" icon-pack="fas"
                    icon="search" v-model="customerLineDropdownFilter" :disabled="isLoading" />
            </b-dropdown-item>

            <b-dropdown-item :separator="true" :focusable="false" />

            <b-dropdown-item v-if="isLoading" :focusable="false">
                <b-skeleton :animated="true" />
            </b-dropdown-item>

            <b-dropdown-item v-for="customerLine in customerLines" :key="customerLine.id" aria-role="listitem"
                @click="customerLineSelected(customerLine)">
                {{ customerLine.lineName }}
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>
<script>
import { securePost } from '../secureFetch.js';
import _ from 'lodash';

export default {
    name: 'CustomerLineDropdown',
    computed: {
        hostUrl: function () {
            return this.$store.state.hostUrl;
        },
    },
    watch: {
        customerLineDropdownFilter: function () {
            this.searchCustomerLines();
        }
    },
    data() {
        return {
            customerLines: [],
            customerLineDropdownFilter: "",
            customerLineDropdownActive: false,
            isLoading: true,
        }
    },
    mounted() {
        this.searchCustomerLines();
    },
    methods: {
        focusFilter() {
            this.$nextTick(() => {
                const filterRef = this.$refs.customerLineDropdownFilterRef;
                filterRef.focus();
            });
        },
        customerLineSelected(customerLine) {
            this.$emit('customerLineSelected', customerLine.id);
            this.$emit('lineSelected', customerLine.lineId);
            this.customerLineDropdownActive = false;
        },
        searchCustomerLines: _.debounce(function () {
            this.customerLines = [];
            this.errorMessage = null;
            this.isLoading = true;

            var postBody = {
                searchTerm: this.customerLineDropdownFilter,
                page: 0,
                resultsPerPage: 25,
            };

            securePost(this.hostUrl + "v1/line/search-customer-lines", postBody)
                .then(data => {
                    if (data && data.success) {
                        this.customerLines = data.customerLines.sort((a, b) =>
                            a.lineName.localeCompare(b.lineName)
                        );
                    } else {
                        this.errorMessage =
                            "Error Retrieving Customer Lines: " + data.message;
                    }

                    this.isLoading = false;
                    this.focusFilter();
                });
        }, 1000),
    },
};
</script>
<style scoped>
.dropdown-content {
    max-height: 20em;
    overflow: auto;
}
</style>