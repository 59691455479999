<template>
    <div class="modal-background">
        <div v-show="!isResultView">
            <div class="modal-card pt-6">
                <header class="modal-card-head">
                    <p class="modal-card-title">{{title}}</p>
                    <button class="delete is-medium" @click="close()" aria-label="close"></button>
                </header>
                <section class="modal-card-body">
                    <div class="content">
                        <p v-if="!isV1Migration">
                            Import one or more customer lines to internally update their service key, user, and password,
                            as well as update their texting service to Twilio. Additionally, this will update the name and
                            message service of the line(s) in Twilio.
                        </p>
                        <p v-else>
                          Update Twilio specific fields in V1 for the specified lines.
                        </p>
                    </div>
                    <div class="label mt-5"> Required Fields </div>
                    <div class="content">
                        <ul class="list">
                            <li class="list-item">Phone Number</li>
                            <li class="list-item">Line Name</li>
                            <li class="list-item" v-if="!isV1Migration">Customer Line Id</li>
                            <li class="list-item">Twilio Subaccount Id</li>
                            <li class="list-item">Twilio Auth Token</li>
                            <li class="list-item">Twilio Message Service Id</li>
                        </ul>
                    </div>

                    <div class="file has-name is-primary is-fullwidth">
                        <label class="file-label">
                            <input class="file-input" type="file" @change="setValue($event)">
                            <span class="file-cta">
                                <span class="icon is-small">
                                    <font-awesome-icon :icon="['fa', 'upload']" />
                                </span>
                                <span class="file-label ml-3">
                                    Upload CSV
                                </span>
                            </span>
                            <span class="file-name">
                                {{ fileName || 'No file chosen...' }}
                            </span>
                        </label>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button :class="isLoading ? 'button is-primary is-loading' : 'button is-primary'" :disabled="!isValid"
                            @click="queueMigration()">
                        Queue Migration
                    </button>
                    <button class="button is-primary" @click="close()">Cancel</button>
                </footer>
            </div>
        </div>
        <div v-show="isResultView">
            <div class="modal-card pt-6">
                <header class="modal-card-head">
                    <p class="modal-card-title">Result</p>
                    <button class="delete is-medium" @click="close()" aria-label="close"></button>
                </header>
                <section class="modal-card-body">
                    <div class="message is-success">
                        <div class="message-header">
                            <p>Success</p>
                        </div>
                        <div class="message-body">
                            <p>Twilio line migration job has been queued.</p>
                            <p>Job Id: {{ jobId }}</p>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" @click="close()">Close</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import { secureForm } from '../../secureFetch.js';

export default {
    name: "TwilioLineMigrationModal",
    props: {
      isV1Migration: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
        hostUrl: function () {
            return this.$store.state.hostUrl;
        },
        isValid: function () {
            return this.file != null;
        },
        fileName: function () {
            return this.file != null ? this.file.name : null;
        },
        title: function () {
            if (this.isV1Migration) return "Migrate V1 Twilio Line(s)";
            return "Migrate Twilio Line(s)";
        },
    },
    data() {
        return {
            file: null,
            isLoading: false,
            isResultView: false,
            jobId: null,
        };
    },
    methods: {
        close() {
            this.file = null;
            this.isLoading = false;
            this.isResultView = false;
            this.jobId = null;
            this.$emit("close");
        },
        setValue(event) {
            this.file = event.target.files[0];
        },
        async queueMigration() {
            this.isLoading = true;

            var formData = new FormData();
            formData.append("ImportFile", this.file);

            const url = this.isV1Migration ? this.hostUrl + "v1/utilityjob/queue-twilio-v1-line-migration" : 
              this.hostUrl + "v1/utilityjob/queue-twilio-line-migration"

            const result = await secureForm(url, formData);

            this.isLoading = false;

            if (result != null && result.success) {
                this.jobId = result.jobId;
                this.isResultView = true;
            }
            else {
                this.$buefy.toast.open({
                    duration: 5000,
                    message: `${result.message ? "Error: " + result.message : "Something went wrong."}`,
                    position: 'is-bottom',
                    type: 'is-danger',
                })
            }
        },
    },
};
</script>

<style scoped>
.modal-card {
    overflow: visible;
    max-width: 820px;
    width: fit-content;
}

.modal-card-body {
    overflow: visible;
    max-width: 780px;
    width: fit-content;
}

.list {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.list-item {
    margin: 0;
}
</style>