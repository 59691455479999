<template>
    <div class="modal-background">
        <div v-show="!isResultView && !isConfirmationView">
            <div class="modal-card pt-6">
                <header class="modal-card-head">
                    <p class="modal-card-title">Transfer V1 Account</p>
                    <button class="delete is-medium" @click="close()" aria-label="close"></button>
                </header>
                <section class="modal-card-body">
                    <div class="content">
                        <p> Migrate customer data for a given account from V1 to Foundation. </p>
                    </div>

                    <div class="columns mb-0 pb-0">
                        <div class="column mb-0 pb-0">
                            <label class="label">V1 Account</label>
                            <div class="field has-addons">
                                <div class="control is-expanded">
                                    <input class="input" type="text" placeholder="Use dropdown to select account..." v-model="accountIdV1" readonly>
                                </div>
                                <V1AccountDropdown @accountDetailsSelected="v1AccountSelected" />
                            </div>
                            <p class="selected-account-name">{{ accountNameV1 || '&nbsp;' }}</p>
                        </div>

                        <div class="column mb-0 pb-0">
                            <label class="label">Foundation Account</label>
                            <div class="field has-addons">
                                <div class="control is-expanded">
                                    <input class="input" type="text" placeholder="Use dropdown to select account..." v-model="accountIdFoundation" readonly>
                                </div>
                                <AccountDropdown @accountDetailsSelected="foundationAccountSelected" />
                            </div>
                            <p class="selected-account-name">{{ accountNameFoundation || '&nbsp;' }}</p>
                        </div>
                    </div>
                    
                    <div class="field is-flex is-align-items-flex-end mb-0 mt-0">
                        <label class="label">Start Date</label>
                        <b-tooltip label="This date indicates the length of message history we are grabbing for the client" type="is-info" position="is-right">
                            <button class="button is-ghost no-underline">
                                <span class="icon is-ghost">
                                    <i class="fas fa-info-circle"></i>
                                </span>
                            </button>
                        </b-tooltip>
                    </div>
                    <b-datepicker
                        v-model="messageFromDate"
                        placeholder="Click to select..."
                        icon="calendar"
                        trap-focus
                        :min-date="minDate"
                        :max-date="maxDate">
                    </b-datepicker>

                    <div class="content mt-2">
                        <p class="mb-1"> This job includes: </p>
                        <ul class="list">
                            <li class="list-item">Migrating Lines</li>
                            <li class="list-item">Migrating Message data, including attachments</li>
                            <li class="list-item">Migrating Conversations data, including integration partner details</li>
                            <li class="list-item">Migrating Opt Status data</li>
                            <li class="list-item">Migrating Persist Value data</li>
                        </ul>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button :class="isLoading ? 'button is-primary is-loading' : 'button is-primary'" :disabled="!isValid"
                        @click="showConfirmation()">Queue Transfer</button>
                    <button class="button is-primary" @click="close()">Cancel</button>
                </footer>
            </div>
        </div>
        <div v-show="isConfirmationView">
            <div class="modal-card pt-6">
                <header class="modal-card-head">
                    <p class="modal-card-title">Confirm Transfer</p>
                    <button class="delete is-medium" @click="closeConfirmation()" aria-label="close"></button>
                </header>
                <section class="modal-card-body">
                    <div class="content">
                        <p>V1 Account: {{ accountNameV1 }}</p>
                        <p>Foundation Account: {{ accountNameFoundation }}</p>
                        <p class="has-text-warning mt-2">Please type "Confirm" and click "OK" to continue.</p>
                        <input class="input" type="text" placeholder="Type 'Confirm'..." v-model="confirmationText">
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button :class="isLoading ? 'button is-primary is-loading' : 'button is-primary'" :disabled="confirmationText !== 'Confirm'"
                        @click="queueTransfer()">OK</button>
                    <button class="button is-primary" @click="closeConfirmation()">Cancel</button>
                </footer>
            </div>
        </div>
        <div v-show="isResultView">
            <div class="modal-card pt-6">
                <header class="modal-card-head">
                    <p class="modal-card-title">Result</p>
                    <button class="delete is-medium" @click="close()" aria-label="close"></button>
                </header>
                <section class="modal-card-body">
                    <div class="message is-success">
                        <div class="message-header">
                            <p>Success</p>
                        </div>
                        <div class="message-body">
                            <p>V1 account transfer job has been queued.</p>
                            <p>Job Id: {{ jobId }}</p>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" @click="closeResults()">Transfer Another Account</button>
                    <button class="button is-primary" @click="close()">Close</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import { securePost } from '../../secureFetch.js';
import AccountDropdown from '../AccountDropdown';
import V1AccountDropdown from '../V1AccountDropdown';

export default {
    name: "AccountMigrationModal",
    components: { AccountDropdown, V1AccountDropdown },
    computed: {
        hostUrl: function () {
            return this.$store.state.hostUrl;
        },
        isValid: function () {
            return (this.accountIdV1 && this.accountIdV1 > 0 &&
                this.accountIdFoundation && this.accountIdFoundation.trim() !== "" &&
                this.messageFromDate);
        },
        maxDate: function () {
            return this.today;
        },
        minDate: function () {
            return new Date(this.today.getFullYear() - 1, this.today.getMonth(), this.today.getDate());
        },
    },
    data() {
        return {
            accountIdV1: null,
            accountNameV1: null,
            accountIdFoundation: null,
            accountNameFoundation: null,
            messageFromDate: null,
            isLoading: false,
            isConfirmationView: false,
            isResultView: false,
            confirmationText: null,
            jobId: null,
            today: new Date(),
        };
    },
    methods: {
        close() {
            this.accountIdV1 = null;
            this.accountNameV1 = null;
            this.accountIdFoundation = null;
            this.accountNameFoundation = null;
            this.messageFromDate = null;
            this.isLoading = false;
            this.isConfirmationView = false;
            this.isResultView = false;
            this.confirmationText = null;
            this.jobId = null;
            this.$emit("close");
        },
        closeConfirmation() {
            this.isConfirmationView = false;
            this.confirmationText = null;
        },
        closeResults() {
            this.accountIdV1 = null;
            this.accountNameV1 = null;
            this.accountIdFoundation = null;
            this.accountNameFoundation = null;
            this.messageFromDate = null;
            this.isResultView = false;
            this.confirmationText = null;
            this.jobId = null;
        },
        v1AccountSelected(account) {
            this.accountIdV1 = account.id;
            this.accountNameV1 = account.name;
        },
        foundationAccountSelected(account) {
            this.accountIdFoundation = account.id;
            this.accountNameFoundation = account.name;
        },
        showConfirmation() {
            this.isConfirmationView = true;
        },
        async queueTransfer() {
            this.isLoading = true;
            this.isConfirmationView = false;

            var postBody = {
                accountIdV1: this.accountIdV1,
                accountIdFoundation: this.accountIdFoundation,
                messageFromDate: this.messageFromDate
            };

            const result = await securePost(this.hostUrl + "v1/utilityjob/queue-migrate-v1-account", postBody);

            this.isLoading = false;

            if (result != null && result.success) {
                this.jobId = result.jobId;
                this.isResultView = true;
            }
            else {
                this.$buefy.toast.open({
                    duration: 5000,
                    message: `${result.message ? "Error: " + result.message : "Something went wrong."}`,
                    position: 'is-bottom',
                    type: 'is-danger',
                })
            }
        },
    },
};
</script>

<style scoped>
.modal-card {
    overflow: visible;
    max-width: 820px;
    width: fit-content;
}

.modal-card-body {
    overflow: visible;
    max-width: 780px;
    width: fit-content;
}

.list {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.list-item {
    margin: 0;
}

.no-underline:hover {
    text-decoration: none;
}
.selected-account-name {
    color: hsl(0, 0%, 48%);
    font-size: 0.875em;
    margin-top: -10px;
}
</style>