<template>
    <section>
        <div class="field is-grouped is-grouped-right">
            <b-dropdown position="is-bottom-left" aria-role="list">
                <template #trigger>
                    <b-button :label="filtersApplied > 0 ? `Filters (${filtersApplied})` : 'Add Filter'" type="is-primary"
                        icon-right="fas fa-filter" />
                </template>
                <b-dropdown-item aria-role="listitem">
                    <div class="control">
                        <b-switch v-model="filterByUser" @click.native="$event.stopPropagation(); return false;">
                            Show Only My Jobs
                        </b-switch>
                    </div>
                </b-dropdown-item>
            </b-dropdown>
            <b-button type="is-primary ml-4" icon-right="fas fa-sync" @click="searchJobs"></b-button>
        </div>
        <b-table :data="jobs" :loading="isLoading" striped paginated backend-pagination :total="totalJobs"
            :per-page="resultsPerPage" :currentPage="currentPage + 1" @page-change="setPage" detailed detail-key="id"
            :show-detail-icon="false" :opened-detailed="defaultOpenedDetails" aria-next-label="Next page"
            aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page">

            <b-table-column field="id" label="Job Id" v-slot="props">
                {{ props.row.id }}
            </b-table-column>

            <b-table-column field="jobType" label="Job Type" v-slot="props">
                {{ props.row.jobType ? jobTypeNameDictionary[props.row.jobType] : 'unknown' }}
            </b-table-column>

            <b-table-column field="created" label="Date Queued" v-slot="props">
                {{ props.row.created ? convertDateToLocal(props.row.created) : 'unknown' }}
            </b-table-column>

            <b-table-column :visible="showUserColumn" field="userId" label="Initiated By" v-slot="props">
                {{ props.row.userId }}
            </b-table-column>

            <b-table-column field="jobStatusType" label="Status" centered v-slot="props">
                <span class="tag is-flex is-rounded mt-1" size="is-medium" :class="getStatusColor(props.row.jobStatusType)">
                    {{ props.row.jobStatusType ? jobStatusTypeNameDictionary[props.row.jobStatusType] : 'unknown' }}
                </span>
            </b-table-column>

            <b-table-column field="messages" label="Messages" centered v-slot="props">
                <div v-show="showDetailsDropdown(props.row)">
                    <a role="button" @click.stop="props.toggleDetails(props.row)">
                        <b-icon icon="exclamation-circle" pack="fas" :type="getMessageIconType(props.row)" size="is-medium" />
                    </a>
                </div>
            </b-table-column>

            <b-table-column centered>
                <template v-slot:header>
                    <b-tooltip label="Log files" type="is-dark">
                        <b-icon pack="fas" icon="file-alt"></b-icon>
                    </b-tooltip>
                </template>
                <template v-slot:default="props">
                    <div v-if="props.row.jobResultFileUrl">
                        <a role="button" @click="downloadLogFile(props.row.jobResultFileUrl)">
                            <b-icon icon="download" pack="fas" type="is-light pt-1" />
                        </a>
                    </div>
                </template>
            </b-table-column>

            <template #detail="props">
                <div class="row" :class="{'mb-4': props.row.jobErrorMessages && props.row.jobErrorMessages.length > 0}" v-if="props.row.jobErrorMessages && props.row.jobErrorMessages.length > 0">
                    <!-- Error Messages -->
                    <div class="columns pa-0 mb-0">
                        <div class="column is-narrow pa-0">
                            <label class="label">Error Messages:</label>
                        </div>
                        <div class="column pa-0">
                            <button class="button is-flex is-small is-primary is-rounded is-pulled-right"
                                @click="copyDatadogLinkToClipboard(props.row)">
                                Copy Datadog Link
                            </button>
                        </div>
                    </div>
                    <div class="field">
                        <textarea class="textarea" :rows="countMessages(props.row.jobErrorMessages)"
                            readonly>{{ formatMessages(props.row.jobErrorMessages) }}</textarea>
                    </div>
                </div>
                <div class="row" v-if="props.row.jobInfoMessages && props.row.jobInfoMessages.length > 0">
                    <!-- Info Messages -->
                    <div class="columns pa-0 mb-0">
                        <div class="column is-narrow pa-0">
                            <label class="label">Info Messages:</label>
                        </div>
                        <div class="column pa-0" v-if="!(props.row.jobErrorMessages && props.row.jobErrorMessages.length > 0)">
                            <button class="button is-flex is-small is-primary is-rounded is-pulled-right"
                                @click="copyDatadogLinkToClipboard(props.row)">
                                Copy Datadog Link
                            </button>
                        </div>
                    </div>
                    <div class="field">
                        <textarea class="textarea" :rows="countMessages(props.row.jobInfoMessages)"
                            readonly>{{ formatMessages(props.row.jobInfoMessages) }}</textarea>
                    </div>
                </div>
            </template>
        </b-table>
    </section>
</template>

<script>
import { securePost } from '../../secureFetch.js';

export default {
    name: 'JobHistory',
    components: {
    },
    computed: {
        hostUrl: function () {
            return this.$store.state.hostUrl;
        },
        currentPage: {
            set(value) {
                this.$store.commit('updateJobHistoryPageFilter', value);
            },
            get() {
                return this.$store.state.jobHistoryFilters.page;
            }
        },
        filterByUser: {
            set(value) {
                this.$store.commit('updateJobHistoryFilterByUser', value);
                this.searchJobs();
            },
            get() {
                return this.$store.state.jobHistoryFilters.filterByUser;
            }
        },
        filtersApplied: function () {
            var filters = 0;

            if (this.$store.state.jobHistoryFilters.filterByUser === true) {
                filters++;
            }

            return filters;
        },
        showUserColumn: {
            get() {
                return this.$store.state.jobHistoryFilters.filterByUser === false;
            }
        },
    },
    data() {
        return {
            isLoading: true,
            errorMessage: null,
            jobs: [],
            resultsPerPage: 25,
            totalPages: 0,
            totalJobs: 0,
            jobTypeNameDictionary: {
                1: "Line Transfer",
                2: "Twilio Line Migration",
                3: "Status Repair",
                4: "Migrate V1 Account",
                5: "Bulk Account Creation",
                6: "Update V1 Bandwidth Lines",
                7: "Create Bandwidth SubAccounts",
                8: "V1 Twilio Line Migration",
                9: "Bulk Bandwidth Line Import",
                10: "Bandwidth Line Migration"
            },
            jobStatusTypeDictionary: {
                Queued: 1,
                Processing: 2,
                Completed: 3,
                Failed: 4,
            },
            jobStatusTypeNameDictionary: {
                1: 'Queued',
                2: 'Processing',
                3: 'Completed',
                4: 'Failed',
            },
            defaultOpenedDetails: [],
        }
    },
    mounted() {
        this.searchJobs();
    },
    methods: {
        searchJobs() {
            this.isLoading = true;
            this.errorMessage = null;
            this.defaultOpenedDetails = [];

            var postBody = {
                page: this.currentPage,
                resultsPerPage: this.resultsPerPage,
                filterByUser: this.filterByUser
            };

            securePost(this.hostUrl + "v1/utilityjob/search-jobs", postBody)
                .then(data => {
                    this.isLoading = false;

                    if (data && data.success) {
                        this.jobs = data.jobs;
                        this.currentPage = data.currentPage;
                        this.totalPages = data.totalPages;
                        this.totalJobs = data.totalJobs;
                    }
                    else {
                        this.jobs = [];
                        this.totalPages = 0;
                        this.errorMessage = "Error Retrieving Job History: " + data.message;
                    }
                });
        },
        setPage(page) {
            this.currentPage = page - 1;
            this.searchJobs();
        },
        getStatusColor(status) {
            switch (status) {
                case this.jobStatusTypeDictionary.Queued:
                    return "is-warning";
                case this.jobStatusTypeDictionary.Processing:
                    return "is-info";
                case this.jobStatusTypeDictionary.Completed:
                    return "is-primary-dark";
                case this.jobStatusTypeDictionary.Failed:
                    return "is-danger";
                default:
                    return "is-light";
            }
        },
        convertDateToLocal(date) {
            return new Date(date + 'Z').toLocaleString({
                timeZone:
                    Intl.DateTimeFormat().resolvedOptions().timeZone
            });
        },
        formatMessages(messages) {
            return messages.replace(/;/g, '\n');
        },
        countMessages(messages) {
            return messages.split(';').length;
        },
        showDetailsDropdown(job) {
            return (job.jobErrorMessages && job.jobErrorMessages.length > 0) || (job.jobInfoMessages && job.jobInfoMessages.length > 0)
        },
        copyDatadogLinkToClipboard(job) {
            var now = new Date();
            var oneMonthAgo = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
            var unixNow = now.getTime();
            var unixOneMonthAgo = oneMonthAgo.getTime();
            var url = "https://app.datadoghq.com/logs?query=%40Properties.JobId%3A" + job.id + "&from_ts=" + unixOneMonthAgo + "&to_ts=" + unixNow + "&live=true";

            navigator.clipboard.writeText(url);
        },
        getMessageIconType(job) {
            if (job.jobErrorMessages && job.jobErrorMessages.length > 0) {
                return 'is-danger';
            } else if (job.jobInfoMessages && job.jobInfoMessages.length > 0) {
                return 'is-light';
            }
        },
        downloadLogFile(fileUrl) {
            const fileName = this.getFileName(fileUrl) + ".csv";
            
            var postBody = {
                url: fileUrl,
                fileName: fileName
            };

            securePost(this.hostUrl + "v1/utilityjob/download-result-file", postBody)
                .then(response => {
                    this.isLoading = false;

                    if (response && response.success) {
                        var link = document.createElement("a");
                        link.download = response.file.fileDownloadName;
                        link.href = `data:text/csv;base64,${response.file.fileContents}`;
                        link.click();
                    }
                    else {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: `${
                                result?.message
                                ? "Error: " + result?.message
                                : "Something went wrong."
                            }`,
                            position: "is-bottom",
                            type: "is-danger",
                        });
                    }
                });
        },

        getFileName(fileUrl) {
            return fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
        },
    }
};
</script>

<style scoped></style>
