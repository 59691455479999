<template>
    <div class="modal-background">
        <div v-show="!isResultView">
            <div class="modal-card pt-6">
                <header class="modal-card-head">
                    <p class="modal-card-title">Bulk Bandwidth Line Import</p>
                    <button class="delete is-medium" @click="close()" aria-label="close"></button>
                </header>
                <section class="modal-card-body">
                    <div class="content">
                        <p>
                            This utility takes in a series of phone numbers and associated information (subAccountId, locationId, etc.), creates a port
                            order for them in Bandwidth (grouped by location), creates an LOA file for each order, and adds the phone numbers to a
                            specified Bandwidth campaign.
                        </p>
                    </div>
                    <div class="label mt-5"> Required Fields: </div>
                    <div class="content">
                        <ul class="list">
                            <li class="list-item">Account Id</li>
                            <li class="list-item">House Number</li>
                            <li class="list-item">Street Name</li>
                            <li class="list-item">City</li>
                            <li class="list-item">State Code</li>
                            <li class="list-item">Zip</li>
                            <li class="list-item">Phone Number (WITHOUT the country code)</li>
                            <li class="list-item">SubAccount Id</li>
                            <li class="list-item">Location Id</li>
                            <li class="list-item">Location Name</li>
                            <li class="list-item">Campaign Id</li>
                        </ul>
                    </div>

                    <div class="file has-name is-primary is-fullwidth">
                        <label class="file-label">
                            <input class="file-input" type="file" @change="setValue($event)">
                            <span class="file-cta">
                                <span class="icon is-small">
                                    <font-awesome-icon :icon="['fa', 'upload']" />
                                </span>
                                <span class="file-label ml-3">
                                    Upload CSV
                                </span>
                            </span>
                            <span class="file-name">
                                {{ fileName || 'No file chosen...' }}
                            </span>
                        </label>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button :class="isLoading ? 'button is-primary is-loading' : 'button is-primary'" :disabled="!isValid"
                            @click="queueLineImport()">
                        Queue Line Import
                    </button>
                    <button class="button is-primary" @click="close()">Cancel</button>
                </footer>
            </div>
        </div>
        <div v-show="isResultView">
            <div class="modal-card pt-6">
                <header class="modal-card-head">
                    <p class="modal-card-title">Result</p>
                    <button class="delete is-medium" @click="close()" aria-label="close"></button>
                </header>
                <section class="modal-card-body">
                    <div class="message is-success">
                        <div class="message-header">
                            <p>Success</p>
                        </div>
                        <div class="message-body">
                            <p>Bulk Bandwidth Line Import job has been queued.</p>
                            <p>Job Id: {{ jobId }}</p>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" @click="close()">Close</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import { secureForm } from '../../secureFetch.js';

export default {
    name: "BulkBandwidthLineImportModal",
    computed: {
        hostUrl: function () {
            return this.$store.state.hostUrl;
        },
        isValid: function () {
            return this.file != null;
        },
        fileName: function () {
            return this.file != null ? this.file.name : null;
        },
    },
    data() {
        return {
            file: null,
            isLoading: false,
            isResultView: false,
            jobId: null,
        };
    },
    methods: {
        close() {
            this.file = null;
            this.isLoading = false;
            this.isResultView = false;
            this.jobId = null;
            this.$emit("close");
        },
        setValue(event) {
            this.file = event.target.files[0];
        },
        async queueLineImport() {
            this.isLoading = true;

            var formData = new FormData();
            formData.append("ImportFile", this.file);

            const result = await secureForm(this.hostUrl + "v1/utilityjob/queue-bulk-bandwidth-line-import", formData);

            this.isLoading = false;

            if (result != null && result.success) {
                this.jobId = result.jobId;
                this.isResultView = true;
            }
            else {
                this.$buefy.toast.open({
                    duration: 5000,
                    message: `${result.message ? "Error: " + result.message : "Something went wrong."}`,
                    position: 'is-bottom',
                    type: 'is-danger',
                })
            }
        },
    },
};
</script>

<style scoped>
    .modal-card {
        overflow: visible;
        max-width: 820px;
        width: fit-content;
    }

    .modal-card-body {
        overflow: visible;
        max-width: 780px;
        width: fit-content;
    }

    .list {
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
    }

    .list-item {
        margin: 0;
    }
</style>