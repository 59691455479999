<template>
    <div class="modal-background">
        <div v-show="!isResultView">
            <div class="modal-card pt-6">
                <header class="modal-card-head">
                    <p class="modal-card-title">Transfer Customer Line</p>
                    <button class="delete is-medium" @click="close()" aria-label="close"></button>
                </header>
                <section class="modal-card-body">
                    <div class="content">
                        <p> Transfer a customer line from one account to another account, moving all of their message and
                            conversation data over. </p>
                    </div>
                    <label class="label">Line Id</label>
                    <div class="field has-addons">
                        <div class="control is-expanded">
                            <input class="input" type="text" placeholder="Line id..." v-model="lineId">
                        </div>
                        <CustomerLineDropdown @lineSelected="lineSelected" />
                    </div>

                    <label class="label">Destination Account Id</label>
                    <div class="field has-addons">
                        <div class="control is-expanded">
                            <input class="input" type="text" placeholder="Account id..." v-model="newAccountId">
                        </div>
                        <AccountDropdown @accountSelected="accountSelected" />
                    </div>
                    <div class="content mt-5">
                        <p> This job includes: </p>
                        <ul class="list">
                            <li class="list-item">Transfering the Customer Line</li>
                            <li class="list-item">Transfering Messages and Conversations</li>
                            <li class="list-item">Transfering Blast Campaigns</li>
                            <li class="list-item">Transfering Webhooks</li>
                            <li class="list-item">Removing Line Responders and Line Response Templates</li>
                            <li class="list-item">Removing Line Permissions</li>
                        </ul>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button :class="isLoading ? 'button is-primary is-loading' : 'button is-primary'" :disabled="!isValid"
                        @click="queueTransfer()">Queue Transfer</button>
                    <button class="button is-primary" @click="close()">Cancel</button>
                </footer>
            </div>
        </div>
        <div v-show="isResultView">
            <div class="modal-card pt-6">
                <header class="modal-card-head">
                    <p class="modal-card-title">Result</p>
                    <button class="delete is-medium" @click="close()" aria-label="close"></button>
                </header>
                <section class="modal-card-body">
                    <div class="message is-success">
                        <div class="message-header">
                            <p>Success</p>
                        </div>
                        <div class="message-body">
                            <p>Line transfer job has been queued.</p>
                            <p>Job Id: {{ jobId }}</p>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" @click="closeResults()">Transfer Another Line</button>
                    <button class="button is-primary" @click="close()">Close</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import { securePost } from '../../secureFetch.js';
import AccountDropdown from '../AccountDropdown';
import CustomerLineDropdown from '../CustomerLineDropdown';

export default {
    name: "LineTransferModal",
    components: { AccountDropdown, CustomerLineDropdown },
    computed: {
        hostUrl: function () {
            return this.$store.state.hostUrl;
        },
        isValid: function () {
            return (this.newAccountId && this.newAccountId.trim() !== "" &&
                this.lineId && this.lineId.trim() !== "");
        },
    },
    data() {
        return {
            newAccountId: null,
            lineId: null,
            isLoading: false,
            isResultView: false,
            errorMessage: null,
            jobId: null,
        };
    },
    mounted() {
    },
    methods: {
        close() {
            this.newAccountId = null;
            this.lineId = null;
            this.isLoading = false;
            this.isResultView = false;
            this.errorMessage = null;
            this.jobId = null;
            this.$emit("close");
        },
        closeResults() {
            this.newAccountId = null;
            this.lineId = null;
            this.isResultView = false;
            this.errorMessage = null;
            this.jobId = null;
        },
        accountSelected(accountId) {
            this.newAccountId = accountId;
        },
        lineSelected(lineId) {
            this.lineId = lineId;
        },
        async queueTransfer() {
            this.isLoading = true;

            var postBody = {
                newAccountId: this.newAccountId,
                lineId: this.lineId,
            };

            const result = await securePost(this.hostUrl + "v1/utilityjob/queue-line-transfer", postBody);

            this.isLoading = false;

            if (result != null && result.success) {
                this.jobId = result.jobId;
                this.isResultView = true;
            }
            else {
                this.$buefy.toast.open({
                    duration: 5000,
                    message: `${result.message ? "Error: " + result.message : "Something went wrong."}`,
                    position: 'is-bottom',
                    type: 'is-danger',
                })
            }
        },
    },
};
</script>

<style scoped>
.modal-card {
    overflow: visible;
    max-width: 820px;
    width: fit-content;
}

.modal-card-body {
    overflow: visible;
    max-width: 780px;
    width: fit-content;
}

.list {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.list-item {
    margin: 0;
}
</style>