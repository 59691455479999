<template>
    <div class="modal-background">
        <div v-show="!isResultView">
            <div class="modal-card pt-6">
                <header class="modal-card-head">
                    <p class="modal-card-title">Create Bandwidth SubAccount(s)</p>
                    <button class="delete is-medium" @click="close()" aria-label="close"></button>
                </header>
                <section class="modal-card-body">
                    <div class="content">
                        <p>
                            Create one or more Bandwidth SubAccounts (Sites). This utility will also create a default Location (SipPeer)
                            for each, enable SMS and MMS for the Location, and assign the appropriate Application to it.
                        </p>
                    </div>
                    <label class="label">Version</label>
                    <div class="field has-addons">
                        <div class="control is-expanded">
                            <input class="input" type="text" placeholder="Version..." v-model="selectedVersion.name">
                        </div>
                        <div :class="versionDropdownActive ? 'dropdown is-active is-right' : 'dropdown is-right'" >
                          <div>
                              <div class="dropdown-trigger">
                                  <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="versionDropdownActive = !versionDropdownActive">
                                      <span class="icon is-small">
                                          <font-awesome-icon :icon="['fa', 'angle-down']" />
                                      </span>
                                  </button>
                              </div>
                              <div class="dropdown-menu" id="dropdown-menu" role="menu">
                                  <div class="dropdown-content">
                                      <hr class="dropdown-divider">
                                      <a v-for="version in versions" :key="version.id"
                                          class="dropdown-item" @click="versionSelected(version)">
                                          {{ version.name }}
                                      </a>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                    <div class="label mt-5"> Required Fields: </div>
                    <div class="content">
                        <ul class="list">
                            <li class="list-item">Account Id</li>
                            <li class="list-item">Account Name</li>
                            <li class="list-item">House Number</li>
                            <li class="list-item">Street Name</li>
                            <li class="list-item">City</li>
                            <li class="list-item">State Code</li>
                            <li class="list-item">Zip</li>
                        </ul>
                    </div>

                    <div class="file has-name is-primary is-fullwidth">
                        <label class="file-label">
                            <input class="file-input" type="file" @change="setValue($event)">
                            <span class="file-cta">
                                <span class="icon is-small">
                                    <font-awesome-icon :icon="['fa', 'upload']" />
                                </span>
                                <span class="file-label ml-3">
                                    Upload CSV
                                </span>
                            </span>
                            <span class="file-name">
                                {{ fileName || 'No file chosen...' }}
                            </span>
                        </label>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button :class="isLoading ? 'button is-primary is-loading' : 'button is-primary'" :disabled="!isValid"
                            @click="queueSubAccountCreation()">
                        Queue SubAccount Creation
                    </button>
                    <button class="button is-primary" @click="close()">Cancel</button>
                </footer>
            </div>
        </div>
        <div v-show="isResultView">
            <div class="modal-card pt-6">
                <header class="modal-card-head">
                    <p class="modal-card-title">Result</p>
                    <button class="delete is-medium" @click="close()" aria-label="close"></button>
                </header>
                <section class="modal-card-body">
                    <div class="message is-success">
                        <div class="message-header">
                            <p>Success</p>
                        </div>
                        <div class="message-body">
                            <p>Create Bandwidth SubAccounts job has been queued.</p>
                            <p>Job Id: {{ jobId }}</p>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" @click="close()">Close</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import { secureForm } from '../../secureFetch.js';

export default {
    name: "CreateBandwidthSubAccountsModal",
    computed: {
        hostUrl: function () {
            return this.$store.state.hostUrl;
        },
        isValid: function () {
            return this.file != null && this.selectedVersion.id != null;
        },
        fileName: function () {
            return this.file != null ? this.file.name : null;
        },
    },
    data() {
        return {
            file: null,
            isLoading: false,
            isResultView: false,
            jobId: null,
            versionDropdownActive: false,
            selectedVersion: { id: null, name: null},
            versions: [
              { id: 1, name: "V1"},
              { id: 2, name: "V2"},
            ],
        };
    },
    methods: {
        close() {
            this.file = null;
            this.isLoading = false;
            this.isResultView = false;
            this.jobId = null;
            this.$emit("close");
        },
        setValue(event) {
            this.file = event.target.files[0];
        },
        async queueSubAccountCreation() {
            this.isLoading = true;

            var formData = new FormData();
            formData.append("ImportFile", this.file);
            formData.append("Version", this.selectedVersion.id);

            const result = await secureForm(this.hostUrl + "v1/utilityjob/queue-create-bandwidth-subaccounts", formData);

            this.isLoading = false;

            if (result != null && result.success) {
                this.jobId = result.jobId;
                this.isResultView = true;
            }
            else {
                this.$buefy.toast.open({
                    duration: 5000,
                    message: `${result.message ? "Error: " + result.message : "Something went wrong."}`,
                    position: 'is-bottom',
                    type: 'is-danger',
                })
            }
        },
        versionSelected(version) {
          this.selectedVersion = version;
          this.versionDropdownActive = false;
        },
    },
};
</script>

<style scoped>
    .modal-card {
        overflow: visible;
        max-width: 820px;
        width: fit-content;
    }

    .modal-card-body {
        overflow: visible;
        max-width: 780px;
        width: fit-content;
    }

    .list {
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
    }

    .list-item {
        margin: 0;
    }
</style>