<template>
    <div class="utilities">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/">Dashboard</router-link></li>
                <li class="is-active"><a href="#" aria-current="page">Utilities</a></li>
            </ul>
        </nav>

        <div class="field is-grouped">
            <h1 class="title">Utilities</h1>
        </div>

        <div class="tabs">
            <ul>
                <li :class="newJobTabActive ? 'is-active' : ''"><a @click="setNewJobTabActive()"> New Job </a></li>
                <li :class="jobHistoryTabActive ? 'is-active' : ''"><a @click="setJobHistoryTabActive()"> Job History </a></li>
            </ul>
        </div>
        
        <JobSelector v-if="newJobTabActive"/>
        <JobHistory v-if="jobHistoryTabActive"/>

    </div>
</template>

<script>
    import JobHistory from '../components/Utilities/JobHistory';
    import JobSelector from '../components/Utilities/JobSelector';

    export default {
        name: 'Utilities',
        components: {
            JobHistory,
            JobSelector,
        },
        computed: {
            hostUrl: function() {
                return this.$store.state.hostUrl;
          },
        },
        data() {
            return {
                isLoading: true,
                errorMessage: null,
                newJobTabActive: true,
                jobHistoryTabActive: false,
            }
        },
        mounted() {
            this.isLoading = false;
        },
        methods: {
            setNewJobTabActive() {
                this.newJobTabActive = true;
                this.jobHistoryTabActive = false;
            },
            setJobHistoryTabActive() {
                this.newJobTabActive = false;
                this.jobHistoryTabActive = true;
            },
        }
    };
</script>

<style scoped>
</style>
