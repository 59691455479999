<template>
    <div class="jobSelector">
        <Loading v-if="isLoading" />
        <div v-else class="columns is-multiline d-flex">
            <div class="card m-4" v-for="jobType in jobTypes" v-bind:key="jobType.name">
                <div class="card-content">
                    <div class="media">
                        <div class="media-content">
                            <p class="title is-4">{{ jobType.name }}</p>
                            <p class="subtitle is-6">{{ jobType.description }}</p>
                        </div>
                    </div>
                </div>
                <footer class="card-footer">
                    <a role="button" class="card-footer-item" @click="showJobModal(jobType.id)">Run Job</a>
                </footer>
            </div>
        </div>

        <component v-bind:is="currentModal" v-bind="props" @close="closeJobModal()" />
    </div>
</template>

<script>
import { secureGet } from '../../secureFetch.js';
import LineTransferModal from './LineTransferModal.vue';
import TwilioLineMigrationModal from './TwilioLineMigrationModal.vue';
import StatusRepairModal from './StatusRepairModal.vue';
import AccountMigrationModal from './AccountMigrationModal.vue';
import BulkAccountCreationModal from './BulkAccountCreationModal.vue';
import UpdateV1BandwidthLinesModal from './UpdateV1BandwidthLinesModal.vue';
import CreateBandwidthSubAccountsModal from './CreateBandwidthSubAccountsModal.vue';
import BulkBandwidthLineImportModal from './BulkBandwidthLineImportModal.vue';
import BandwidthLineMigrationModal from './BandwidthLineMigrationModal.vue';
import Loading from '../../components/Loading';

export default {
    name: 'JobSelector',
    components: {
        Loading,
    },
    computed: {
        hostUrl: function () {
            return this.$store.state.hostUrl;
        },
    },
    data() {
        return {
            isLoading: true,
            errorMessage: null,
            jobTypes: [],
            jobTypeComponentDictionary: {
                LineTransfer: 1,
                TwilioLineMigration: 2,
                StatusRepair: 3,
                V1AccountMigration: 4,
                BulkAccountCreation: 5,
                UpdateV1BandwidthLines: 6,
                CreateBandwidthSubAccounts: 7,
                TwilioV1LineMigration: 8,
                BulkBandwidthLineImport: 9,
                BandwidthLineMigration: 10
            },
            isModalActive: false,
            currentModal: null,
            props: null,
        }
    },
    mounted() {
        this.getJobTypes();
    },
    methods: {
        getJobTypes() {
            this.isLoading = true;

            secureGet(this.hostUrl + "v1/utilityjob/job-types")
                .then(data => {
                    this.isLoading = false;

                    if (data && data.success) {
                        this.jobTypes = data.jobTypes;
                    }
                });
        },
        showJobModal(jobTypeCode) {
            switch (jobTypeCode) {
                case this.jobTypeComponentDictionary.LineTransfer:
                    this.currentModal = LineTransferModal;
                    break;
                case this.jobTypeComponentDictionary.TwilioLineMigration:
                    this.currentModal = TwilioLineMigrationModal;
                    break;
                case this.jobTypeComponentDictionary.StatusRepair:
                    this.currentModal = StatusRepairModal;
                    break;
                case this.jobTypeComponentDictionary.V1AccountMigration:
                    this.currentModal = AccountMigrationModal;
                    break;
                case this.jobTypeComponentDictionary.BulkAccountCreation:
                    this.currentModal = BulkAccountCreationModal;
                    break;
                case this.jobTypeComponentDictionary.UpdateV1BandwidthLines:
                    this.currentModal = UpdateV1BandwidthLinesModal;
                    break;
                case this.jobTypeComponentDictionary.CreateBandwidthSubAccounts:
                    this.currentModal = CreateBandwidthSubAccountsModal;
                    break;
                case this.jobTypeComponentDictionary.TwilioV1LineMigration:
                    this.currentModal = TwilioLineMigrationModal;
                    this.props = {
                      isV1Migration: true,
                    };
                    break;
                case this.jobTypeComponentDictionary.BulkBandwidthLineImport:
                    this.currentModal = BulkBandwidthLineImportModal;
                    break;
                case this.jobTypeComponentDictionary.BandwidthLineMigration:
                    this.currentModal = BandwidthLineMigrationModal;
                    break;
                default:
                    break;
            }
        },
        closeJobModal() {
            this.currentModal = null;
            this.props = null;
        },
    }
};
</script>

<style scoped>
.d-flex {
    flex-direction: row;
}

.card {
    min-width: 300px;
    max-width: 400px;
    flex: 1;
    position: relative;
}

.card-content {
    flex-grow: 1;
    padding-bottom: 4.5rem;
}

.card-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}
</style>
