<template>
    <div :class="accountDropdownActive ? 'dropdown is-active ' + `${this.isLeft == true ? 'is-left' : 'is-right'}` : 'dropdown ' + `${this.isLeft ? 'is-left' : 'is-right'}`" >
        <div>
            <div class="dropdown-trigger">
                <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="accountDropdownSelected">
                    <span class="icon is-small">
                        <font-awesome-icon :icon="['fa', 'angle-down']" />
                    </span>
                </button>
            </div>
            <div class="dropdown-menu" id="dropdown-menu" role="menu">
                <div class="dropdown-content">
                    <div class="field dropdown-item">
                        <div class="control has-icons-left">
                        <input type="text" placeholder="Account Name or Id" class="input is-transparent" ref="accountDropdownFilterRef" v-model="accountDropdownFilter" @blur="delayCloseAccountDropdown">
                        <span class="icon is-left">
                            <font-awesome-icon :icon="['fa', 'search']" />
                        </span>
                        </div>
                    </div>
                    <hr class="dropdown-divider">
                    <a v-for="account in accountsFiltered" :key="account.id"
                        class="dropdown-item" @click="accountSelected(account)">
                        {{ account.name }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { securePost } from '../secureFetch.js';

    export default {
        name: 'AccountDropdown',
        props: {
            isLeft: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            hostUrl: function() {
                return this.$store.state.hostUrl;
            },
            accountsFiltered: function() {
                var accountFilter = "";
                if (this.accountDropdownFilter) {
                    accountFilter = this.accountDropdownFilter.toUpperCase();
                }
                return this.accounts.filter(function (account) {
                    return account.name.toUpperCase().includes(accountFilter) ||
                        account.id.toUpperCase().includes(accountFilter);
                });
            }
        },
        data() {
            return {
                accounts: [],
                accountDropdownFilter: "",
                accountDropdownActive: false
            }
        },
        mounted() {
            this.searchAccounts();
        },
        methods: {
            delayCloseAccountDropdown() {
                setTimeout(() => this.accountDropdownActive = false, 500);
            },
            accountDropdownSelected() {
                this.accountDropdownActive = !this.accountDropdownActive;
                this.$nextTick(() => {
                    const filterRef = this.$refs.accountDropdownFilterRef;
                    filterRef.focus();
                });
            },
            accountSelected(account) {
                this.$emit('accountSelected', account.id);
                this.$emit('accountDetailsSelected', account);
                this.accountDropdownActive = false;
            },
            searchAccounts() {
                this.accounts = [];
                this.errorMessage = null;

                var postBody = {
                    searchTerm: this.accountSearch,
                    page: 0,
                    resultsPerPage: 9999
                };

                securePost(this.hostUrl + "v1/account/search-accounts", postBody)
                    .then(data => {
                        if (data && data.success) {
                            this.accounts = data.accounts.sort((a, b) => a.name.localeCompare(b.name));
                        }
                        else {
                            this.errorMessage = "Error Retrieving Accounts: " + data.message;
                        }
                    });
                }
            },
        };
</script>

<style scoped>
    .dropdown-content {
        max-height: 20em;
        overflow: auto;
    }
</style>