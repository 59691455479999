<template>
    <div class="loading">
        <section class="hero is-medium">
            <div class="hero-body has-text-centered">
                <p class="subtitle">
                    Trying really hard...
                </p>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: 'Loading'
    };
</script>

<style scoped>
</style>