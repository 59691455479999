<template>
  <div class="modal-background">
    <div v-show="!isResultView">
      <div class="modal-card pt-6">
        <header class="modal-card-head">
          <p class="modal-card-title">Create Multiple Accounts</p>
          <button
            class="delete is-medium"
            @click="close()"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body" id="import-modal-body">
          <div class="content">
            <p>
              You can create multiple accounts using a CSV import.
              <a @click="downloadTemplate()">Download a template here.</a> NOTE:
              Since this is a CSV file, do not use commas in the name fields.
            </p>
            <p>
              Select a permission template for the accounts you are uploading.
              What is selected will apply to all of the accounts in your upload.
            </p>
          </div>
          <label class="label">Permission Template</label>
          <PermissionTemplateDropdown
            ref="permissionTemplateDropdown"
            @templateSelected="setPermissionTemplate"
          />
          <div class="label mt-5">Required Fields</div>
          <div class="content">
            <ul class="list">
              <li class="list-item">Account Name</li>
              <li class="list-item">Company Name</li>
              <li class="list-item">Contact Email</li>
              <li class="list-item">Contact First Name</li>
              <li class="list-item">Contact Last Name</li>
              <li class="list-item">TimeZone</li>
              <li class="list-item">Internal Salesforce Id</li>
            </ul>
          </div>
          <div class="file has-name is-primary is-fullwidth">
            <label class="file-label">
              <input
                class="file-input"
                type="file"
                name="accountImportFile"
                @change="fileSelected($event)"
              />
              <span class="file-cta">
                <span class="icon is-small">
                  <font-awesome-icon :icon="['fa', 'upload']" />
                </span>
                <span class="file-label ml-3"> Upload CSV </span>
              </span>
              <span class="file-name">
                {{ fileName || "No file chosen..." }}
              </span>
            </label>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button
            :class="
              isLoading ? 'button is-primary is-loading' : 'button is-primary'
            "
            :disabled="!isValid"
            @click="queueAccountCreation()"
          >
            Queue Account Creation
          </button>
          <button class="button is-primary" @click="close()">Cancel</button>
        </footer>
      </div>
    </div>
    <div v-show="isResultView">
      <div class="modal-card pt-6">
        <header class="modal-card-head">
          <p class="modal-card-title">Result</p>
          <button
            class="delete is-medium"
            @click="close()"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          <div class="message is-success">
            <div class="message-header">
              <p>Success</p>
            </div>
            <div class="message-body">
              <p>Bulk Account Creation job has been queued.</p>
              <p>Job Id: {{ jobId }}</p>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" @click="closeResults()">
            Create More Accounts
          </button>
          <button class="button is-primary" @click="close()">Close</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import PermissionTemplateDropdown from "../PermissionTemplateDropdown";
import { secureForm } from "../../secureFetch.js";

export default {
  name: "ImportUserModal",
  components: { PermissionTemplateDropdown },
  computed: {
    hostUrl: function () {
      return this.$store.state.hostUrl;
    },
    isValid: function () {
      return this.file != null && this.permissionTemplateJson != null;
    },
    fileName: function () {
      if (this.file) {
        return this.file.name;
      }
      return null;
    },
  },
  data() {
    return {
      file: null,
      permissionTemplateJson: null,
      isLoading: false,
      isResultView: false,
      jobId: null,
    };
  },
  mounted() {},
  methods: {
    close() {
      this.file = null;
      this.isLoading = false;
      this.isResultView = false;
      this.permissionTemplateJson = null;
      this.jobId = null;
      this.clearSelectedTemplate();
      this.$emit("close");
    },
    closeResults() {
      this.file = null;
      this.permissionTemplateJson = null;
      this.jobId = null;
      this.clearSelectedTemplate();
      this.isResultView = false;
    },
    fileSelected(event) {
      this.file = event.target.files[0];
    },
    downloadTemplate() {
      const headers =
        "Account Name,Company Name,Contact Email,Contact First Name,Contact Last Name,TimeZone,Internal Salesforce Id,Meevo Tenant Id,Meevo Cluster Id,Message Plan Count,Message Plan Billing Term,Message Plan Term Start Date, Message Plan Term Billing Day\n";
      const templateAsBase64 = Buffer.from(headers).toString("base64");
      var link = document.createElement("a");
      link.download = "account_bulk_creation_CRUDy_template.csv";
      link.href = `data:text/csv;base64,${templateAsBase64}`;
      link.click();
    },
    setPermissionTemplate(template) {
      this.permissionTemplateJson = template;
    },
    clearSelectedTemplate() {
      this.permissionTemplateJson = null;
      this.$refs.permissionTemplateDropdown.clearSelectedTemplate();
    },
    async queueAccountCreation() {
      this.isLoading = true;

      var formData = new FormData();
      formData.append("AccountsImportFile", this.file);
      formData.append("PermissionTemplateJson", this.permissionTemplateJson);

      const result = await secureForm(
        this.hostUrl + "v1/utilityjob/queue-bulk-account-creation",
        formData
      );

      this.isLoading = false;

      if (result != null && result.success) {
        this.jobId = result.jobId;
        this.isResultView = true;
      } else {
        this.$buefy.toast.open({
          duration: 5000,
          message: `${
            result?.message
              ? "Error: " + result?.message
              : "Something went wrong."
          }`,
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
  },
};
</script>

<style scoped>
.modal-card {
  overflow: visible;
  max-width: 820px;
  width: fit-content;
}

.modal-card-body {
  overflow: visible;
  max-width: 780px;
  width: fit-content;
}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.list-item {
  width: 45%;
  margin: 0;
}

a {
  text-decoration: underline;
  color: hsl(207, 61%, 53%);
}
</style>
