<template>
    <div class="modal-background">
        <div v-show="!isResultView">
            <div class="modal-card pt-6">
                <header class="modal-card-head">
                    <p class="modal-card-title">Update V1 Bandwidth </p>
                    <button class="delete is-medium" @click="close()" aria-label="close"></button>
                </header>
                <section class="modal-card-body">
                    <div class="content">
                        <p> Update Bandwidth specific fields in V1 for the specified account lines. </p>
                    </div>

                    <div class="columns mb-0 pb-0">
                        <div class="column mb-0 pb-0">
                            <label class="label">V1 Account</label>
                            <div class="field has-addons">
                                <div class="control is-expanded">
                                    <input class="input" type="text" placeholder="Use dropdown to select account..." v-model="accountIdV1" readonly>
                                </div>
                                <V1AccountDropdown @accountDetailsSelected="v1AccountSelected" />
                            </div>
                            <p class="selected-account-name">{{ accountNameV1 || '&nbsp;' }}</p>
                        </div>
                    </div>

                    <div class="file has-name is-primary is-fullwidth">
                        <label class="file-label">
                            <input class="file-input" type="file" @change="setValue($event)">
                            <span class="file-cta">
                                <span class="icon is-small">
                                    <font-awesome-icon :icon="['fa', 'upload']" />
                                </span>
                                <span class="file-label ml-3">
                                    Upload CSV
                                </span>
                            </span>
                            <span class="file-name">
                                {{ fileName || 'No file chosen...' }}
                            </span>
                        </label>
                    </div>

                    <div class="content mt-2">
                        <p class="mb-1"> The expected .csv column headers are as follows: </p>
                        <ul class="list">
                            <li class="list-item">Phone Number</li>
                            <li class="list-item">Bandwidth Application Id</li>
                            <li class="list-item">Bandwidth User Id</li>
                            <li class="list-item">Bandwidth Password</li>
                        </ul>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button :class="isLoading ? 'button is-primary is-loading' : 'button is-primary'" :disabled="!isValid"
                        @click="queueUpdate()">Queue Update</button>
                    <button class="button is-primary" @click="close()">Cancel</button>
                </footer>
            </div>
        </div>
        <div v-show="isResultView">
            <div class="modal-card pt-6">
                <header class="modal-card-head">
                    <p class="modal-card-title">Result</p>
                    <button class="delete is-medium" @click="close()" aria-label="close"></button>
                </header>
                <section class="modal-card-body">
                    <div class="message is-success">
                        <div class="message-header">
                            <p>Success</p>
                        </div>
                        <div class="message-body">
                            <p>Update V1 Bandwidth lines job has been queued.</p>
                            <p>Job Id: {{ jobId }}</p>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" @click="closeResults()">Update Additional Lines</button>
                    <button class="button is-primary" @click="close()">Close</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import { secureForm } from '../../secureFetch.js';
import V1AccountDropdown from '../V1AccountDropdown';

export default {
    name: "UpdateV1BandwidthLinesModal",
    components: { V1AccountDropdown },
    computed: {
        hostUrl: function () {
            return this.$store.state.hostUrl;
        },
        isValid: function () {
            return (this.accountIdV1 && this.accountIdV1 > 0 && this.file != null);
        },
        fileName: function () {
            return this.file != null ? this.file.name : null;
        },
    },
    data() {
        return {
            accountIdV1: null,
            accountNameV1: null,
            isLoading: false,
            isResultView: false,
            jobId: null,
            file: null
        };
    },
    methods: {
        close() {
            this.accountIdV1 = null;
            this.accountNameV1 = null;
            this.isLoading = false;
            this.isResultView = false;
            this.jobId = null;
            this.file = null;
            this.$emit("close");
        },
        setValue(event) {
            this.file = event.target.files[0];
        },
        closeResults() {
            this.accountIdV1 = null;
            this.accountNameV1 = null;
            this.isResultView = false;
            this.jobId = null;
            this.file = null;
        },
        v1AccountSelected(account) {
            this.accountIdV1 = account.id;
            this.accountNameV1 = account.name;
        },
        async queueUpdate() {
            this.isLoading = true;

            var formData = new FormData();
            formData.append("ImportFile", this.file);
            formData.append("AccountIdV1", this.accountIdV1)

            const result = await secureForm(this.hostUrl + "v1/utilityjob/queue-update-v1-bandwidth-lines", formData);

            this.isLoading = false;

            if (result != null && result.success) {
                this.jobId = result.jobId;
                this.isResultView = true;
            }
            else {
                this.$buefy.toast.open({
                    duration: 5000,
                    message: `${result.message ? "Error: " + result.message : "Something went wrong."}`,
                    position: 'is-bottom',
                    type: 'is-danger',
                })
            }
        },
    },
};
</script>

<style scoped>
.modal-card {
    overflow: visible;
    max-width: 820px;
    width: fit-content;
}

.modal-card-body {
    overflow: visible;
    max-width: 780px;
    width: fit-content;
}

.list {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.list-item {
    margin: 0;
}

.no-underline:hover {
    text-decoration: none;
}
.selected-account-name {
    color: hsl(0, 0%, 48%);
    font-size: 0.875em;
    margin-top: -10px;
}
</style>