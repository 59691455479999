<template>
    <div :class="templateDropdownActive ? 'dropdown is-active' : 'dropdown'">
        <div class="dropdown-trigger">
            <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="templateDropdownSelected">
                <span v-if="!selectedTemplate.id">Select a template.</span>
                <span>{{ selectedTemplate.name }}</span>
                <span class="icon is-small">
                    <font-awesome-icon :icon="['fa', 'angle-down']" />
                </span>
            </button>
        </div>
        <div class="dropdown-menu" id="dropdown-menu" role="menu">
            <div class="dropdown-content">
                <div class="field dropdown-item">
                    <div class="control has-icons-left">
                        <input type="text" placeholder="Template Name" class="input is-transparent" ref="templateDropdownFilterRef" v-model="templateDropdownFilter" @blur="delayCloseTemplateDropdown">
                        <span class="icon is-left">
                            <font-awesome-icon :icon="['fa', 'search']" />
                        </span>
                    </div>
                </div>
                <hr class="dropdown-divider">
                <a v-for="template in templatesFiltered" :key="template.id"
                    :class="template.id == selectedTemplate.id ? 'dropdown-item is-active' : 'dropdown-item'" @click="templateSelected(template)">
                    {{ template.name }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import { secureGet } from '../secureFetch.js';

    export default {
        name: 'PermissionTemplateDropdown',
        computed: {
            hostUrl: function() {
                return this.$store.state.hostUrl;
            },
            templatesFiltered: function() {
                var templateFilter = "";
                if (this.templateDropdownFilter) {
                    templateFilter = this.templateDropdownFilter;
                }
                return this.templates.filter(function (template) {
                    return template.name.toUpperCase().match(templateFilter.toUpperCase())
                });
            }
        },
        data() {
            return {
                templates: [],
                templateDropdownFilter: "",
                templateDropdownActive: false,
                selectedTemplate: {
                    id: null,
                    name: "",
                    templateJson: '[]'
                },
            }
        },
        mounted() {
            this.getPermissionTemplates();
            this.emitInterface();
        },
        methods: {
            delayCloseTemplateDropdown() {
                setTimeout(() => this.templateDropdownActive = false, 500);
            },
            templateDropdownSelected() {
                this.templateDropdownActive = !this.templateDropdownActive;
                this.$nextTick(() => {
                    const filterRef = this.$refs.templateDropdownFilterRef;
                    filterRef.focus();
                });
            },
            templateSelected(template) {
                this.selectedTemplate = template;
                this.$emit('templateSelected', template.templateJson);
                this.templateDropdownActive = false;
            },
            getPermissionTemplates() {
                secureGet(this.hostUrl + "v1/permissiontemplate/all-permission-templates")
                    .then(data => {
                        if (data && data.success) {
                            this.templates = data.permissionTemplates;
                        }
                    });
            },
            clearSelectedTemplate() {
                this.selectedTemplate = {
                    id: null,
                    name: "",
                    templateJson: '[]'
                };
            },
            emitInterface() {
                this.$emit("interface", {
                    clearSelectedTemplate: () => this.clearSelectedTemplate(),
                });
            },
        },
    };
</script>

<style scoped>
    .dropdown-content {
        max-height: 25em;
        overflow: auto;
    }
</style>